import React, { Component } from "react";
import { Table, Button, Row, Col, DatePicker } from "antd";
import ReactECharts from 'echarts-for-react';

import moment from "moment";
import { Select } from 'antd';
const { Option } = Select;

const { Column } = Table;

class StatisticPage extends Component {
  render() {
    const { list, loading, filterConditions, base } = this.props;
    const option1 = {
      title: {
        text: '活跃人数',
        left: 'center'
      },
      xAxis: {
        type: 'category',
        data: base && base.activeData.map(item => { return item.date }),
        axisLabel: { interval: 0, rotate: 30 }
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          data: base && base.activeData.map(item => { return item.count }),
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
        }
      ]
    };
    const option2 = {
      title: {
        text: '版本分布',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          data: base && base.versionData.map(item => {
            return { value: item.count, name: `${item.appVersion}(${item.buildVersion})`}
          }),
          type: 'pie',
          radius: '50%',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    };
    const option3 = {
      title: {
        text: '系统分布',
        // subtext: 'Fake Data',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          data: base && base.platformData.map(item => {
            return { value: item.count, name: `${item.version}`}
          }),
          type: 'pie',
          radius: '50%',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    };
    return (
      <div className={"listpage-container"}>
        <div className="header" style={{"justifyContent": "flex-start"}} >
          <DatePicker value={filterConditions.beginTime} onChange={this.onChange} allowClear={false}/>
          <Select defaultValue="iOS" style={{ width: 100 }} onChange={this.handleChange}>
            <Option value="iOS">iOS</Option>
            <Option value="Android">Android</Option>
          </Select>
        </div>
        
        <div className={"base-container"}>
          <Row justify="space-around">
            <Col span={6}>
              <ReactECharts option={option1} />
            </Col>
            <Col span={6}>
              <ReactECharts option={option2} />
            </Col>
            <Col span={6}>
              <ReactECharts option={option3} />
            </Col>
          </Row>
        </div>
        <div className={"table-container"}>
            {
              (() => {
                  return this.renderColumns(list, loading);
              })()
            }
        </div>
      </div>
    )
  }

  handleChange = (value) => {
    const { fetchInitData, filterConditions, updateFilterConditions, fetchBaseInfo } = this.props;
    fetchInitData({ 
      pageId: filterConditions.pageId,
      eventId: filterConditions.eventId,
      beginTime: filterConditions.beginTime,
      endTime: filterConditions.endTime,
      sort: filterConditions.sort,
      os: value
    });
    fetchBaseInfo(moment(filterConditions.beginTime).format('YYYY-MM-DD'), value);
    updateFilterConditions({
      ...filterConditions,
      os: value
    });
  };

  onChange = (date, dateString) => {
    const { fetchInitData, filterConditions, updateFilterConditions, fetchBaseInfo } = this.props;
    let beginTime = moment(date).startOf("day");
    let endTime = moment(date).startOf("day").add(1, 'days');
    fetchInitData({ 
      pageId: filterConditions.pageId,
      eventId: filterConditions.eventId,
      beginTime: beginTime,
      endTime: endTime,
      sort: filterConditions.sort,
      os: filterConditions.os
    });
    fetchBaseInfo(moment(beginTime).format('YYYY-MM-DD'), filterConditions.os);
    updateFilterConditions({
      ...filterConditions,
      beginTime: beginTime,
      endTime: endTime
    });
  }
  renderColumns = (list, loading) => {
    return (
      <Table size="middle" dataSource={list} loading={loading} pagination={true} rowKey="id">
        <Column title="用户id" dataIndex="uid" key="uid" width="10%" />
        <Column title="用户角色" dataIndex="appId" key="appId" width="10%" render={this.renderColumnAppId} />
        {/* <Column title="AppName" dataIndex="appName" key="appName" width="10%"/> */}
        <Column title="AppVersion" dataIndex="appVersion" key="appVersion" width="10%" render={this.renderColumnAppVersion}/>
        <Column title="页面ID" dataIndex="pageId" key="pageId" width="15%" />
        <Column title="事件ID" dataIndex="eventId" key="eventId" width="15%" />
        <Column title="发生时间" dataIndex="addTime" key="addTime" width="15%" render={this.renderColumnTime} />
        <Column title="操作" dataIndex="action" width="10%" render={this.renderColumnAction} />
      </Table>
    );
  };

  renderColumnAppId = text => {
    if (text === "0") {
      return "学生";
    } else if (text === "1") {
      return "家长";
    } else if (text === "2") {
      return "老师";
    } else {
      return text;
    }
  }

  renderColumnAppVersion = (text, record, index) => {
    return `${text}(${record.buildVersion})`
  }

  renderColumnTime = (text) => {
    return this.formatTime(text)
  }

  renderColumnAction = (text, record, index) => {
    return <Button onClick={this.toDetail(record.uid, record.addTime - 15 * 60 * 1000, record.addTime + 60*60*1000)}>日志详情</Button>;
  };

  toDetail = (uid, beginTime, endTime) => () => {
    this.props.history.push(`/statistic-detail?uids[]=${uid}&beginTime=${this.formatTime(beginTime)}&endTime=${this.formatTime(endTime)}`);
  };

  formatTime = (time) => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }

}

export default StatisticPage;