

export const nativeLogTypeConfigs = [
  {
    logType: 1,
    logTypeName: "exception catch 日志",
    displayColor: "#32CD32"
  },
  {
    logType: 2,
    logTypeName: "custom 代码日志",
    displayColor: "#2f54eb"
  },
  {
    logType: 3,
    logTypeName: "network日志",
    displayColor: "#5c3317"
  },
  {
    logType: 84,
    logTypeName: "性能日志",
    displayColor: "#5c3317"
  }
];

export const webLogTypeConfigs = [
  {
    logType: 1,
    logTypeName: "日志类型1",
    displayColor: "#32CD32"
  },
  {
    logType: 2,
    logTypeName: "日志类型2",
    displayColor: "#2f54eb"
  },
  {
    logType: 3,
    logTypeName: "日志类型3",
    displayColor: "#5c3317"
  }
];
