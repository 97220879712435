import moment from "moment";

export const initialState = {
  filterConditions: {
    pageId: "room", 
    eventId: "join_room",
    sort: "desc",
    beginTime: moment().startOf("day"),
    endTime: moment().startOf("day").add(1, 'days'),
    os: "iOS"
  },
  list: [],
  base: null,
  loading: false
};