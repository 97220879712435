import React, { Component } from "react";
import ReactJson from "react-json-view";

import { Timeline, Tag, Button } from "antd";
import { LeftOutlined } from '@ant-design/icons';

import moment from "moment";
import "./style.scss";

const eventConfig = {
  show_course:'课前准备页',
  camera_ready:'摄像头调整页面',
  join_room:'进入教室',
  upload_music_score:'上传曲谱',
  switch_music_score:'切换曲谱',
  sync_music_score:'同步曲谱',
  switch_appliance:'切换教具',
  close_zhifa:'关闭指法指导',
  request_open_zhifa:'请求开启指法指导',
  receive_open_zhifa_req:'收到开启指法指导请求',
  agree_open_zhifa:'同意开启指法指导',
  reject_open_zhifa:'拒绝开启指法指导',
  show_practise:'查看练习要求',
  add_star:'加星星',
  receive_star:'收到星星',
  capture_music_score:'截屏',
  mute:'静音',
  reset_room:'重置教室',
  retry_join_whiteboard:'重连白板',
  exit_room:'退出教室',
  exception:'异常行为',
};

class StatisticDetailPage extends Component {
  renderItem = (item) => {
    return (
      <Timeline.Item label={this.formatTime(item.addTime)}  color="green" key={item.id}>
        {this.renderAppId(item.appId)}
        <Tag color="green">{this.renderEventId(item.eventId)}</Tag>
        {
          this.renderExt(item.ext)
        }
      </Timeline.Item>
    )
  }

  renderExt = text => {
    if (text == null || text == '') {
      return ""
    }
    let json = JSON.parse(text);
    return (
      <ReactJson
        name="info"
        collapsed={true}
        displayDataTypes={false}
        src={json}
      />
    )
  }

  renderAppId = text => {
    if (text === "0") {
      return (<Tag color="orange">学生</Tag>);
    } else if (text === "1") {
      return (<Tag color="gold">家长</Tag>);
    } else if (text === "2") {
      return (<Tag color="blue">老师</Tag>);
    } else {
      return (<Tag color="blue">{text}</Tag>);
    }
  }

  renderEventId = text => {
    if (eventConfig[text]) {
      return `${eventConfig[text]}(${text})`
    } else {
      return text;
    }
  }

  formatTime = (time) => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }

  render() {
    const { list } = this.props;
    return (
      <div className="statistic-detail-container">
         <div className="header">
          <Button icon={<LeftOutlined />} onClick={this.handleBackToListButtonClicked}>返回列表</Button>
        </div>
        <div className="content">
          <Timeline mode={"left"}>
            {
              list.map((item, index) => {
                return this.renderItem(item);
              })
            }
          </Timeline>
        </div>
      </div>
    )
  }

  handleBackToListButtonClicked = () => {
    const { history } = this.props;
    history.push("/statistic-list")
  }
}

export default StatisticDetailPage;