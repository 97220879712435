import React, { Component } from "react";
import { connect } from "react-redux";
import {message} from "antd";
import StatisticPage from "../components/statistic-page/index";
import { updateFilterConditions, fetchList, fetchInitData, fetchBaseInfo } from "./redux/action";

import moment from "moment";

export class StatisticList extends Component {
  componentDidCatch(error, errorInfo) {
    message.error("页面异常！");
    this.props.history.replace("/");
  }

  componentDidMount() {
    const { fetchInitData, filterConditions, fetchBaseInfo } = this.props;
    fetchInitData({ 
      pageId: filterConditions.pageId,
      eventId: filterConditions.eventId,
      beginTime: filterConditions.beginTime,
      endTime: filterConditions.endTime,
      sort: filterConditions.sort,
      os: filterConditions.os
    });
    fetchBaseInfo(moment(filterConditions.beginTime).format('YYYY-MM-DD'), filterConditions.os);
  }  

  render() {
    return (
      <StatisticPage
        {...this.props}
      />
    )
  }
}

export function mapStateToProps(state) {
  return {
    ...state.statisticList
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    updateFilterConditions: newFilterConditions => dispatch(updateFilterConditions(newFilterConditions)),
    fetchList: (filterConditions) => dispatch(fetchList(filterConditions)),
    fetchInitData: (params) => dispatch(fetchInitData(params)),
    fetchBaseInfo: (date, os) => dispatch(fetchBaseInfo(date, os))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticList);