import React, { Component } from "react";
import { connect } from "react-redux";
import {message} from "antd";
import StatisticDetailPage from "../components/statistic-detail-page/index";

import { parse } from "qs";
import { updateFilterConditions, fetchList, fetchInitData } from "./redux/action";

export class StatisticDetail extends Component {
  componentDidCatch(error, errorInfo) {
    message.error("页面异常！");
    this.props.history.replace("/");
  }

  componentDidMount() {
    let params = {};
    if (this.props.location) {
      params = parse(this.props.location.search, { ignoreQueryPrefix: true });
    }
    const { fetchInitData } = this.props;
    fetchInitData(params);
  }  

  render() {
    return (
      <StatisticDetailPage
        {...this.props}
      />
    )
  }
}

export function mapStateToProps(state) {
  return {
    ...state.statisticDetail
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    updateFilterConditions: newFilterConditions => dispatch(updateFilterConditions(newFilterConditions)),
    fetchList: (filterConditions) => dispatch(fetchList(filterConditions)),
    fetchInitData: (params) => dispatch(fetchInitData(params))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticDetail);