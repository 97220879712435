import {initialState} from "./initial-state";

export const STATISTIC_UPDATE_LIST = "STATISTIC_UPDATE_LIST";
export const STATISTIC_UPDATE_BASE = "STATISTIC_UPDATE_BASE";
export const STATISTIC_UPDATE_FILTER_CONDITIONS = "STATISTIC_UPDATE_FILTER_CONDITIONS";
export const STATISTIC_CHANGE_LOADING = "STATISTIC_CHANGE_LOADING";

export default (state = initialState, action) => {
  switch (action.type) {
    case STATISTIC_UPDATE_LIST:
      return {
        ...state,
        list: action.list
      };
    case STATISTIC_UPDATE_BASE:
      return {
        ...state,
        base: action.base
      }
    case STATISTIC_UPDATE_FILTER_CONDITIONS:
      return {
        ...state,
        filterConditions: action.filterConditions
      };
    case STATISTIC_CHANGE_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};
