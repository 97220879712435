import {
  STATISTIC_UPDATE_LIST,
  STATISTIC_UPDATE_BASE,
  STATISTIC_UPDATE_FILTER_CONDITIONS,
  STATISTIC_CHANGE_LOADING
} from "./reducer";
import { fetchStatisticBaseInfoApi, fetchStatisticListApi } from "../../../common/api";

export function fetchInitData(params) {
  return (dispatch, getState) => {
    return fetchStatisticListApi(params)
      .then(data => {
        dispatch({
          type: STATISTIC_UPDATE_LIST,
          list: data
        })
      })
  }
}

export function fetchBaseInfo(date, os) {
  return (dispatch, getState) => {
    return fetchStatisticBaseInfoApi(date, os)
      .then(data => {
        dispatch({
          type: STATISTIC_UPDATE_BASE,
          base: data
        })
      })
  }
}

export function updateFilterConditions(newFilterConditions) {
  return (dispatch, getState) => {
    dispatch({
      type: STATISTIC_UPDATE_FILTER_CONDITIONS,
      filterConditions: newFilterConditions
    });
  };
}

export function changeLoading(loading) {
  return (dispatch, getState) => {
    dispatch({
      type: STATISTIC_CHANGE_LOADING,
      loading: loading
    });
  };
}

export function fetchList(params) {
  return (dispatch, getState) => {
    return fetchStatisticListApi(params)
      .then(data => {
        dispatch({
          type: STATISTIC_UPDATE_LIST,
          list: data
        });
      })
  };
}
